<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :loading="loading"
    :showCloseButton="false"
    :show="true"
    :titleText="$t('forceUpdateWindow.title')"
  >
    <template>
      <div class="force-update-modal">
        <div class="content">
          <p>{{ $t("forceUpdateWindow.text") }}</p>
        </div>
        <div class="footer">
          <a class="new-app-link" :href="appUrl">
            <button class="ui-button ui-button-green search-button">
              {{ $t("general.update") }}
            </button>
          </a>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import { mapGetters } from "vuex";

export default {
  name: "ForceUpdateWindow",
  components: {
    BaseWindow,
  },
  computed: {
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
    appUrl() {
      if (this.getRealPlatform === "ios") {
        return "https://apps.apple.com/us/app/turn-poker/id816868197";
      } else if (this.getRealPlatform === "android") {
        return "https://play.google.com/store/apps/details?id=com.turngs.turnpoker&hl=tr&gl=US";
      }
      return "#";
    },
  },
  data() {
    return {
      loading: false,
      selectedId: null,
    };
  },
  methods: {
    closeWindow() {},
  },
};
</script>

<style lang="scss" scoped>
.force-update-modal {
  position: relative;
  width: 98%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #83212d;
  border-radius: 20px;
  .heading {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
  }
  .content {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #ffff;
    p {
      font-size: 40px;
    }
  }
  .footer {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    .new-app-link {
      text-decoration: none;
    }
    .search-button {
      text-transform: uppercase;
    }
  }
}
</style>
